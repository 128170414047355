<template>
    <div class="bg-neutral-100">
        <NuxtImg
            width="480"
            height="270"
            :loading="loading"
            :alt="`Fahrzeugmodel ${item.modelName} ${item.name} mit ${item.fuel}`"
            class="w-full aspect-video object-cover"
            :src="`/uploads/vehicle/${item.id}/${item.id}.webp`"
        />

        <div class="px-8 py-5">
            <div class="text-primary font-medium text-sm mb-1">{{ item.brandName }}</div>
            <FrontpageHeadline level="5" class="mb-2">{{ item.modelName }} {{ item.name }}</FrontpageHeadline>
            <div>{{ description }}</div>
            <div class="text-xs text-gray-500 mt-1" v-if="!hideCondition">{{ item.conditions }}</div>

            <div v-if="account" class="flex flex-wrap justify-between items-center gap-x-8 gap-y-2 mt-6">
                <span class="text-primary text-2xl font-bold w-24">
                    {{ item.discount }}{{ item.euroDiscount ? '€' : '%' }}
                </span>
                <NuxtLink :to="`/abrufschein?model_id=${item.modelId}`">
                    <FrontpageButton size="small">Zum Abrufschein</FrontpageButton>
                </NuxtLink>
            </div>

            <div v-else class="flex flex-wrap justify-between items-center gap-x-8 gap-y-2 mt-6">
                <span class="text-gray-600 text-xs w-32">Konditionen nur für Mitglieder sichtbar</span>
                <FrontpageButton @click="layout.login = true" size="small">Login</FrontpageButton>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        default: () => {},
        required: true,
    },

    loading: {
        type: String,
        default: 'lazy',
    },

    hideCondition: {
        type: Boolean,
        default: false,
    },
});

const { account, layout } = useStore();

const description = [
    props.item.commercial ? 'Nutzfahrzeug' : props.item.type || undefined,
    props.item.transmission || undefined,
    props.item.fuel || undefined,
]
    .filter(Boolean)
    .join(', ');
</script>
