<template>
    <main>
        <FrontpageTeaser
            headline-tag="h1"
            image="startseite"
            class="xl:py-36 relative"
            title="Exklusive Sonderkonditionen für Firmenwagen"
            title-class="max-w-xl mb-2 relative z-20"
        >
            <div class="grid grid-cols-2 gap-8">
                <div class="col-span-full lg:col-span-1">
                    <FrontpageHeadline level="6" weight="600" class="max-w-xl text-pretty drop-shadow mb-4">
                        Für PKW & Nutzfahrzeuge. Ohne Mitgliedsbeitrag. <br class="hidden lg:inline" />Mit kostenfreiem
                        Abrufschein.
                    </FrontpageHeadline>

                    <p class="max-w-lg drop-shadow">
                        Für Gewerbetreibende, Firmenkunden, Unternehmer, Freiberufler und Selbstständige. Für alle Branchen.
                    </p>

                    <div v-if="!account" class="flex flex-wrap gap-y-2 gap-x-8 mt-8">
                        <NuxtLink to="/mitglied-werden#formular">
                            <FrontpageButton class="min-w-48">Mitglied werden</FrontpageButton>
                        </NuxtLink>
                        <FrontpageButton
                            outline
                            class="min-w-48 !bg-transparent hover:!bg-white/10 hover:brightness-125 !text-white"
                            @click="layout.login = true"
                        >
                            Login
                        </FrontpageButton>
                    </div>
                </div>
                <div class="2xl:pl-20 col-span-full lg:col-span-1">
                    <!-- <video
                        class="aspect-[10/7] w-full max-w-lg xl:max-w-xl 2xl:max-w-2xl lg:top-1/2 lg:-translate-y-1/2 rounded-lg object-cover lg:absolute z-10 mx-auto"
                        controls
                    >
                        <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                        <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg" />
                        Your browser does not support HTML video.
                    </video> -->
                </div>
            </div>
        </FrontpageTeaser>

        <section class="pt-24 lg:pt-36">
            <div class="container-sm grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Interaktiver<br />
                    Firmenwagen-Finder.<br />
                    Für alle Branchen.
                </FrontpageHeadline>
                <FrontpageVehicleFinder />
            </div>
        </section>

        <section v-if="vehicles.length" class="pt-24 lg:pt-36">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Top Deals
                </FrontpageHeadline>
                <FrontpageTopDeals :vehicles="vehicles" link-more />
            </div>
        </section>

        <section class="pt-24 lg:pt-36">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Ihre Vorteile
                </FrontpageHeadline>

                <div class="grid gap-4 grid-cols-4">
                    <FrontpageIconBox
                        icon-name="person_add"
                        headline="Ihre kostenfreie Mitgliedschaft"
                        class="col-span-full md:col-span-2 lg:col-span-1"
                    >
                        Ihre Mitgliedschaft ist
                        <strong>unverbindlich, jederzeit kündbar</strong>
                        und <strong>kostenfrei</strong>. Keine Aufnahme- oder Jahresgebühren.<br /><br />
                        Sie gehen keinerlei Verpflichtung ein.
                    </FrontpageIconBox>

                    <FrontpageIconBox
                        icon-name="task"
                        headline="Ihre kostenfreien Abrufscheine"
                        class="col-span-full md:col-span-2 lg:col-span-1"
                    >
                        Als <strong>Mitglied</strong> sind Sie berechtigt und erhalten
                        <strong>alle Abrufscheine kostenfrei.</strong><br /><br />
                        <span class="text-gray-600 text-xs">
                            Bei Inanspruchnahme der ersten Leistung entsteht eine einmalige Datengebühr von nur 45 Euro
                            netto. Weitere Kosten entstehen nicht.
                        </span>
                    </FrontpageIconBox>

                    <FrontpageIconBox
                        icon-name="support_agent"
                        headline="Ihr persönlicher Service-Scout Tim"
                        class="col-span-full md:col-span-2 lg:col-span-1"
                    >
                        <strong>Erfahrung</strong> und <strong>Freundlichkeit</strong> trifft Verständnis und
                        Professionalität. Das ist Tim, Ihr Service-Scout.<br /><br />
                        Er freut sich darauf, Sie kennenzulernen.
                    </FrontpageIconBox>

                    <FrontpageIconBox
                        icon-name="verified"
                        headline="Ihr Mitglieder Online-Magazine"
                        class="col-span-full md:col-span-2 lg:col-span-1"
                    >
                        Verpassen Sie keine Angebote, indem Sie <strong>gratis</strong> die
                        <strong>OTK insight</strong> abonnieren. Sie können die OTK insight jederzeit wieder abbestellen.
                    </FrontpageIconBox>
                </div>

                <NuxtLink v-if="!account" to="/mitglied-werden#formular" class="mx-auto">
                    <FrontpageButton>Mitglied werden</FrontpageButton>
                </NuxtLink>
            </div>
        </section>

        <section class="pt-24 lg:pt-36">
            <div class="container-sm">
                <FrontpageHeadline level="3" class="max-w-xl mb-8"> Was uns antreibt </FrontpageHeadline>
                <div class="grid grid-cols-12 gap-y-8 md:gap-x-16">
                    <div class="col-span-12 md:col-span-5 max-w-md">
                        <p class="mb-4 hyphens-auto">
                            Wir engagieren uns leidenschaftlich für Ihren wirtschaftlichen Erfolg. Denn Ihr Erfolg treibt
                            auch unser Wachstum voran.
                        </p>
                        <p class="hyphens-auto">
                            Eine Partnerschaft, die für alle Seiten Vorteile schafft - fair, nachhaltig und mit einem
                            ehrlichen Lächeln.
                        </p>
                    </div>

                    <div class="col-span-12 md:col-span-7">
                        <ul class="grid gap-8 sm:grid-cols-2 font-medium">
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Seit 2017 erfolgreich im Markt
                            </li>
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Über 26.000 Mitglieder
                            </li>
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                <div>
                                    4,8
                                    <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                    <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                    <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                    <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                    <Icon
                                        :size="22"
                                        name="star_half"
                                        fill
                                        class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5"
                                    />
                                    <br />
                                    <div>Kundenbewertung</div>
                                </div>
                            </li>
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Rahmenverträge mit einer Vielzahl attraktiver Marken
                            </li>
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                40+ Jahre Erfahrung in der Automobilbranche
                            </li>
                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Kompetenzpartner von DEHOGA
                            </li>

                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Kooperation mit LEASYS Full-Service-Leasing für sorgenfreie Mobilität
                            </li>

                            <li class="flex gap-2">
                                <Icon name="check" :weight="700" class="text-primary" />
                                Partnerschaft mit BVI
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt-24 lg:pt-36">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Auf die Meinung unserer Mitglieder dürfen wir stolz sein.
                </FrontpageHeadline>
                <FrontpageTestimonials />
                <NuxtLink v-if="!account" to="/mitglied-werden#formular" class="mx-auto">
                    <FrontpageButton>Mitglied werden</FrontpageButton>
                </NuxtLink>
            </div>
        </section>

        <section class="pt-24 lg:pt-36">
            <div class="container-sm grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Beste Marken - Beste Preise - Beste Deals
                </FrontpageHeadline>
                <FrontpageBrands />
            </div>
        </section>

        <section class="pt-24 lg:pt-36">
            <div class="container-sm grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
                    Q & A
                </FrontpageHeadline>
                <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
                    <template v-for="item in questions" :key="item.id">
                        <FrontpageIconBox
                            hover
                            class="cursor-pointer"
                            icon-name="contact_support"
                            @click="handleModal(item)"
                        >
                            <div v-html="item.question"></div>
                        </FrontpageIconBox>
                    </template>

                    <Modal v-model="modal.show">
                        <Card class="m-auto w-full max-w-lg">
                            <template #label>
                                <span class="px-4 pb-1.5 pt-2 block" v-html="modal.question"></span>
                            </template>
                            <div class="px-4 py-2" v-html="modal.answer"></div>
                        </Card>
                    </Modal>
                </div>
            </div>
        </section>

        <section class="pt-24 lg:pt-36" :class="{ 'py-24 lg:py-36': account }">
            <div class="container-sm">
                <FrontpageContactBox />
            </div>
        </section>

        <section v-if="!account" class="py-24 lg:py-36">
            <div class="container-sm grid gap-16 text-center">
                <FrontpageHeadline level="3" class="max-w-sm lg:max-w-xl text-center mx-auto">
                    Mitglieder-Service
                </FrontpageHeadline>
                <p class="max-w-3xl mx-auto -mt-8">
                    Bleiben Sie über unsere Angebote, Sonderkonditionen und Neuerungen mit dem Online Mitglieder-Magazin OTK
                    insight immer auf dem Laufenden. Dieser Service ist kostenlos und Sie können ihn nach Ihrer Anmeldung
                    abonnieren und jederzeit kündigen.
                </p>

                <div class="flex flex-wrap gap-y-2 gap-x-8 justify-center">
                    <NuxtLink to="/mitglied-werden#formular">
                        <FrontpageButton class="min-w-48 w-full">Mitglied werden</FrontpageButton>
                    </NuxtLink>

                    <FrontpageButton outline class="min-w-48" @click="layout.login = true">Login</FrontpageButton>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
useSeoMeta({
    title: 'OTK Handelskontor für deutsche Kaufleute',
    description:
        'Exklusive Sonderkonditionen für Firmenwagen. Für PKW & nutzfahrzeuge. Ohne Mitgliedsbeitrag. Mit kostenfreiem Abrufschein.',
});

const { layout, account } = useStore();
const { query } = useRoute();

const { data } = await useFetch('/api/frontpage/site', {
    method: 'POST',
    body: {
        index: 0,
        init: 3,
    },
});

const { topDeals, questions } = data.value;

onMounted(() => {
    if (query.login === null) {
        layout.value.login = true;
    }
});

const vehicles = ref(topDeals.items);

const modal = reactive({
    show: false,
    answer: '',
    question: '',
});

function handleModal(item) {
    modal.question = item.textQuestion;
    modal.answer = item.answer;
    modal.show = true;
}
</script>
