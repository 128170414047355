<template>
    <div class="grid md:grid-cols-2 overflow-hidden" :class="{ 'border border-gray-300 rounded-xl': !popup }">
        <div class="relative hidden md:block">
            <NuxtImg
                width="500"
                loading="lazy"
                alt="Bild Tim Schäling"
                class="w-full h-full object-cover"
                src="/images/schaeling-tim.jpg"
                sizes="2px md:500px"
            />
            <div class="p-6 bg-primary absolute bottom-0 -right-8 rounded-t-lg w-60 text-white">
                <div class="text-xl font-bold uppercase">Bester Service</div>
                <div class="text-xl font-bold uppercase">Beste Beratung</div>
                <div class="text-xl font-bold uppercase">Bester Tim</div>
                <p class="text-xs mt-2">Ich bin kein Avatar, ich begegne Ihnen ganz persönlich.</p>
            </div>
        </div>

        <div class="p-4 md:p-16 bg-white">
            <FormWrapper ref="form" :keypress-enter="submitForm">
                <div class="grid gap-4">
                    <div class="flex gap-4 items-center mb-4">
                        <NuxtImg
                            width="100"
                            loading="lazy"
                            alt="Avatar Tim Schäling"
                            class="size-16 rounded-full object-cover md:hidden"
                            src="/images/schaeling-tim.jpg"
                            sizes="200px"
                        />
                        <div>
                            <div class="uppercase text-sm lg:text-base">TIM - Ihr persönlicher Service-Scout</div>
                            <FrontpageHeadline level="3"> Kontakt </FrontpageHeadline>
                        </div>
                    </div>

                    <div v-if="!account" class="grid md:grid-cols-2 gap-4">
                        <FormInput
                            v-model="formData.firstname"
                            required
                            name="firstname"
                            placeholder="Vorname"
                            class="*:border-gray-300"
                        />
                        <FormInput
                            v-model="formData.lastname"
                            required
                            name="lastname"
                            placeholder="Nachname"
                            class="*:border-gray-300"
                        />
                        <FormInput
                            v-model="formData.email"
                            required
                            name="email"
                            placeholder="E-Mail"
                            class="col-span-full *:border-gray-300"
                        />
                    </div>

                    <FormTextarea
                        v-model="formData.message"
                        placeholder="Ihre Nachricht"
                        name="message"
                        required
                        :rows="rows"
                    />
                    <div class="flex flex-col">
                        <FrontpageButton class="mx-auto md:mr-0" :loading label="senden" @click="submitForm" />
                        <div class="mx-auto md:mr-0 mt-4 font-medium">Mobil: 01 57 . 33 19 12 55</div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    </div>
</template>

<script setup>
defineProps({
    popup: {
        type: Boolean,
        default: false,
    },
});

const rows = ref(4);
const form = ref(null);
const loading = ref(false);
const formData = reactive({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
});

const { account, layout } = useStore();
const { addNotification } = useNotification();

async function submitForm() {
    loading.value = true;

    try {
        await $fetch('/api/frontpage/contact/form-submit', {
            headers: useRequestHeaders(),
            method: 'POST',
            body: {
                form: {
                    firstname: formData.firstname || undefined,
                    lastname: formData.lastname || undefined,
                    email: formData.email || undefined,
                    message: formData.message || undefined,
                },
            },
        });

        layout.value.scout = false;

        addNotification({
            type: 'success',
            text: 'Ihre Nachricht ist bei uns eingegangen.',
        });

        setTimeout(() => {
            formData.firstname = '';
            formData.lastname = '';
            formData.email = '';
            formData.message = '';
        }, 500);
    } catch (error) {
        form.value.handleErrors(error.data.message);
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
}
defineExpose({
    rows,
    formData,
});
</script>
