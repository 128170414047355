<template>
    <div
        ref="form"
        class="sm:p-4 bg-white overflow-hidden duration-300"
        :class="{
            'sm:shadow-lg rounded-b-xl ': !popup,
            'lg:p-2': state.scrolled,
        }"
    >
        <div
            class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4"
            :class="{
                'gap-4 ': popup,
                'lg:gap-2': state.scrolled,
            }"
        >
            <FormSelect
                v-model="formData.brand_id"
                class="*:border-gray-300"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
                :options="options.brands"
                key-name="name"
                key-value="value"
                placeholder="Marke"
                @focus="loadData"
            />

            <FormSelect
                v-model="formData.model_id"
                class="*:border-gray-300"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
                :options="filteredModel()"
                key-name="name"
                key-value="value"
                placeholder="Modell"
                @focus="loadData"
                @change="handleModelChange"
            />

            <FormSelect
                v-model="formData.fuel_id"
                class="*:border-gray-300"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
                :search-box="false"
                :options="options.fuels"
                key-name="name"
                key-value="value"
                name="fuel_id"
                placeholder="Kraftstoff"
                @focus="loadData"
            />

            <FormSelect
                v-model="formData.type_id"
                class="*:border-gray-300"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
                :search-box="false"
                :options="options.types"
                key-name="name"
                key-value="value"
                name="type_id"
                placeholder="Fahrzeugart"
                @focus="loadData"
            />

            <FormSelect
                v-model="formData.transmission_id"
                class="*:border-gray-300"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
                :search-box="false"
                :options="options.transmissions"
                key-name="name"
                key-value="value"
                name="transmission_id"
                placeholder="Getriebe"
                @focus="loadData"
            />

            <FormCheckbox
                v-model="formData.commercial"
                label="Nutzfahrzeuge"
                label-class="truncate"
                class="rounded-md *:h-full border border-gray-300 *:px-2.5 cursor-pointer"
            />

            <FormCheckbox
                v-model="formData.top_deal"
                label="Top Deals"
                label-class="truncate"
                class="rounded-md *:h-full border border-gray-300 *:px-2.5 cursor-pointer"
            />

            <FormInput
                v-if="account"
                v-model="formData.discount"
                class="*:border-gray-300"
                name="discount"
                :reset-icon="false"
                placeholder="Kondition in %"
                :min="0"
                type="number"
                @focus="loadData"
                :class="{ 'lg:*:min-h-8': state.scrolled }"
            />

            <Tooltip v-else label="Nur für Mitglieder">
                <FormInput
                    v-model="formData.discount"
                    class="*:border-gray-300"
                    name="discount"
                    :reset-icon="false"
                    placeholder="Kondition in %"
                    :min="0"
                    disabled
                    type="number"
                    @focus="loadData"
                    :class="{ 'lg:*:min-h-8': state.scrolled }"
                />
            </Tooltip>

            <div class="hidden lg:block"></div>

            <FrontpageButton
                class="max-sm:col-span-2"
                :class="{ 'lg:py-1 lg:h-8 ': state.scrolled }"
                :loading="state.loading"
                label="suchen"
                @click="submitForm"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    query: {
        type: Object,
        default: null,
    },

    popup: {
        type: Boolean,
        default: false,
    },
});

const { account } = useStore();
const emits = defineEmits(['update']);

const options = reactive({
    fuels: [],
    types: [],
    brands: [],
    models: [],
    transmissions: [],
});

const form = ref(null);

const formData = reactive({
    model_id: null,
    brand_id: null,
    commercial: null,
    discount: null,
    top_deal: null,
    fuel_id: null,
    type_id: null,
    transmission_id: null,
});

const state = reactive({
    loaded: false,
    loading: false,
    timeout: null,
    scrolled: false,
});

if (props.query) {
    await loadData();

    formData.brand_id = parseInt(props.query.brand_id);
    formData.model_id = parseInt(props.query.model_id);
    formData.top_deal = Boolean(props?.query?.top_deal);
    formData.commercial = Boolean(props?.query?.commercial);
    formData.fuel_id = parseInt(props?.query?.fuel_id);
    formData.type_id = parseInt(props?.query?.type_id);
    formData.transmission_id = parseInt(props?.query?.transmission_id);
    formData.discount = parseInt(props?.query?.discount);
}

function onScroll() {
    const { top } = form.value.getBoundingClientRect();
    state.scrolled = top <= 96; // header height;
}

onMounted(() => {
    state.timeout = setTimeout(loadData, 10000);

    if (props.query) {
        window.addEventListener('scroll', onScroll);
    }
});

onUnmounted(() => {
    clearTimeout(state.timeout);
    if (props.query) {
        window.removeEventListener('scroll', onScroll);
    }
});

function filteredModel() {
    if (!formData.brand_id) {
        return options.models;
    }

    return options.models.filter((model) => model.brand_id === formData.brand_id);
}

function handleModelChange(option) {
    if (option) {
        formData.brand_id = option.brand_id;
    }
}

async function loadData() {
    if (state.loaded) {
        return;
    }

    const data = await $fetch('/api/frontpage/component/vehicle-finder', {
        method: 'POST',
    });

    for (const key in data) {
        options[key] = data[key];
    }

    state.loaded = true;
    clearTimeout(state.timeout);
}

async function submitForm() {
    state.loading = true;

    const data = Object.fromEntries(Object.entries(formData).filter(([_, value]) => value));
    const params = new URLSearchParams(data).toString();

    if (props.query) {
        navigateTo(`/firmenwagen-finder?${params}#fahrzeuge`).then(() => {
            emits('update', data, state);
        });
        return;
    }

    setTimeout(() => {
        navigateTo(`/firmenwagen-finder?${params}#fahrzeuge`).then(() => {
            state.loading = false;
        });
    }, 500);
}
</script>
