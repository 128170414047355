<template>
    <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <slot />

        <Teleport v-if="show" to="#teleports">
            <Transition
                class="transition duration-300"
                enter-active-class="delay-500"
                enter-from-class="opacity-0 scale-50 "
                leave-to-class="opacity-0 scale-50"
                @after-leave="show = active"
                @enter="onTransitionEnter"
            >
                <div v-if="active" ref="tooltip" :style="style" class="fixed pointer-events-none z-50" :class="contentClass">
                    <div
                        class="bg-gray-800 rounded-md text-xs py-1 px-2 text-gray-100/90 relative"
                        :style="{ left: `-${diff.x}px` }"
                    >
                        {{ label }}
                    </div>
                    <Icon
                        v-if="align === 'center-top'"
                        class="absolute left-1/2 -translate-x-1/2 -bottom-3.5 text-gray-800"
                        name="arrow_drop_down"
                        :size="26"
                    />
                    <!-- <Icon
                        v-else-if="align === 'center-bottom'"
                        class="absolute left-1/2 -translate-x-1/2 -top-3.5 text-gray-800"
                        name="arrow_drop_down"
                        :size="24"
                    /> -->
                </div>
            </Transition>
        </Teleport>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },

    label: {
        type: String,
        default: '',
    },

    contentClass: {
        type: String,
        default: '',
    },

    maxWidth: {
        type: Number,
        default: 200,
    },

    offset: {
        type: Array,
        default: () => {
            return [0, 0];
        },
    },

    align: {
        type: String,
        default: 'center-top',
    },
});

const emit = defineEmits(['update:modelValue']);

const tooltip = ref(null);
const show = ref(false);
const active = ref(false);
const diff = ref({
    x: 0,
    y: 0,
});

const rect = ref({
    w: 0,
    h: 0,
    x: 0,
    y: 0,
});

const style = computed(() => {
    {
        const style = {
            maxWidth: props.maxWidth + 'px',
        };

        if (props.align === 'center-top') {
            style.left = Math.round(rect.value.x) + Math.round(rect.value.w / 2) + props.offset[0] + 'px';
            style.top = Math.round(rect.value.y) + props.offset[1] - 6 + 'px';
            style.transform = 'translate(-50%, -100%)';
        }

        if (props.align === 'center-bottom') {
            style.left = Math.round(rect.value.x) + Math.round(rect.value.w / 2) + props.offset[0] + 'px';
            style.top = Math.round(rect.value.y) + Math.round(rect.value.h) + 6 + 'px';
            style.transform = 'translate(-50%, 0%)';
        }

        return style;
    }
});

function calculateRect(event) {
    // const frame = this.$el.ownerDocument.defaultView.frameElement;
    const { x, y, width, height } = event.target.getBoundingClientRect();
    Object.assign(rect.value, { x, y, w: width, h: height });

    // if (frame) {
    //     const { left, top } = frame.getBoundingClientRect();
    //     Object.assign(rect.value, { x: x + left, y: top + y });
    // }
}

function onTransitionEnter() {
    nextTick(() => {
        const { right } = tooltip.value.getBoundingClientRect();
        const difference = right + 8 - window.innerWidth;

        if (difference > 0) {
            diff.value.x = difference;
        }
    });
}

function onMouseEnter(event) {
    rect.value.valueOffset = calculateRect(event);
    show.value = true;

    diff.value.x = 0;
    diff.value.y = 0;

    nextTick(() => {
        active.value = true;
        emit('update:modelValue', true);
    });
}

function onMouseLeave() {
    nextTick(() => {
        active.value = false;
        emit('update:modelValue', false);
    });
}
</script>
