<template>
    <Body
        class="bg-white text-gray-800 antialiased font-poppins"
        :class="{ 'overflow-hidden ': layout.open || layout.login }"
    >
        <div class="flex flex-col min-h-screen">
            <FrontpageHeader />

            <slot name="default"></slot>
            <FrontpageFooter />

            <div class="fixed right-8 bottom-5 grid gap-2 max-lg:hidden z-20">
                <button
                    @click="layout.finder = true"
                    class="bg-primary bg-gradient-to-r from-black/10 via-white/10 to-black/10 rounded-xl relative pr-16 h-16 flex hover:brightness-125 scale-90 hover:scale-100 duration-200"
                >
                    <span class="rounded-full ring ring-white bg-primary size-16 flex absolute top-0 -right-4">
                        <Icon name="manage_search" class="m-auto text-white" :size="48" />
                    </span>
                    <span class="text-white font-medium my-auto pl-4 leading-5"> Firmenwagen-<br />Finder</span>
                </button>

                <button
                    @click="layout.scout = true"
                    class="bg-primary bg-gradient-to-r from-black/10 via-white/10 to-black/10 rounded-xl relative pr-16 h-16 flex hover:brightness-125 scale-90 hover:scale-100 duration-200"
                >
                    <span class="rounded-full ring ring-white bg-primary size-16 flex absolute top-0 -right-4">
                        <Icon name="contact_support" class="m-auto translate-y-0.5 text-white" :size="48" />
                    </span>
                    <span class="text-white font-medium my-auto pl-4 leading-5">Service-Scout<br />Tim</span>
                </button>
            </div>

            <Modal v-if="!account" v-model="layout.login">
                <FrontpageLogin />
            </Modal>

            <Modal v-model="layout.finder">
                <div
                    class="grow-0 md:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-md:bg-white max-md:absolute max-md:inset-0"
                >
                    <FrontpageVehicleFinder
                        :query="$route.query"
                        @update="layout.finder = false"
                        popup
                        class="m-auto w-full p-4 md:p-6"
                    />
                </div>
            </Modal>
            <Modal v-model="layout.scout">
                <div
                    class="grow-0 md:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-md:bg-white max-md:absolute max-md:inset-0"
                >
                    <FrontpageContactBox class="m-auto" popup />
                </div>
            </Modal>

            <Modal v-model="layout.newsletter">
                <FrontpageNewsletter class="m-auto" />
            </Modal>
        </div>
    </Body>
</template>

<style>
@import url('~/assets/styles/tailwind.css');
@import url('~/assets/fonts/poppins/fontface.css');
</style>

<script setup>
useHead({
    htmlAttrs: {
        lang: 'de',
    },
});

const { account, layout } = useStore();
</script>
