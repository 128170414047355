<template>
    <component :is="tag" v-bind="$attrs" :class="[levelClasses, weightClasses]" class="font-bold">
        <slot></slot>
    </component>
</template>

<script setup>
const props = defineProps({
    tag: {
        type: String,
        default: 'div',
    },

    weight: {
        type: [Number, String],
        default: 600,
        validator(value) {
            return [300, 400, 500, 600, 700, 800].includes(parseInt(value));
        },
    },

    level: {
        type: [Number, String],
        default: 3,
        validator(value) {
            return [1, 2, 3, 4, 5, 6].includes(parseInt(value));
        },
    },
});

const weightClasses = computed(() => {
    const map = {
        300: 'font-light',
        400: 'font-normal',
        500: 'font-medium',
        600: 'font-semibold',
        700: 'font-bold',
        800: 'font-extrabold',
    };

    return map[parseInt(props.weight)];
});

const levelClasses = computed(() => {
    const map = {
        1: 'text-3xl md:text-5xl',
        2: 'text-3xl md:text-4xl',
        3: 'text-2xl md:text-3xl',
        4: 'text-xl md:text-2xl',
        5: 'text-lg md:text-xl',
        6: 'text-base md:text-lg',
    };

    return map[parseInt(props.level)];
});
</script>
