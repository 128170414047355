<template>
    <button
        :disabled="isDisabled && !fakeDisabled"
        :type="type"
        class="rounded-lg font-medium transition duration-150 relative uppercase tracking-wider"
        :class="[interactivityClasses, sizeClasses, colorClasses]"
    >
        <transition name="fade">
            <div v-if="loading" class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
                <svg class="animate-spin w-5 h-5" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
            </div>
        </transition>

        <div
            class="transition flex items-center gap-2 pointer-events-none justify-center"
            :class="[{ 'opacity-0': loading }, contentClass]"
        >
            <slot>
                <div class="text-ellipsis whitespace-nowrap overflow-hidden">
                    {{ label }}
                </div>
            </slot>
        </div>
    </button>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    layout: {
        type: String,
        default: 'primary',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: [String, Boolean],
        default: false,
    },

    fakeDisabled: {
        type: Boolean,
        default: false,
    },

    contentClass: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'button',
    },
    size: {
        type: String,
        default: 'medium', // Options: 'xsmall', 'small', 'medium', 'large'
    },

    outline: {
        type: Boolean,
        default: false,
    },
});

const isDisabled = computed(() => props.loading || props.disabled);

const colorClasses = computed(() => {
    const colors = {
        solid: {
            primary: 'border-2 border-primary hover:border-cyan-700 bg-primary text-white hover:bg-cyan-700',
            success: 'border-2 border-green-600 hover:border-green-500 bg-green-600 text-white hover:bg-green-500',
        },
        outline: {
            primary: 'bg-white text-primary border-2 border-primary hover:bg-primary hover:text-white',
            success: 'bg-white text-green-600 border-2 border-green-600 hover:bg-green-600 hover:text-white',
        },
    };

    return colors[props.outline ? 'outline' : 'solid'][props.layout];
});

const sizeClasses = computed(() => {
    const sizeMap = {
        // xsmall: 'px-3 py-1.5 text-xs',
        small: 'px-4 py-2 text-xs',
        medium: 'px-8 py-2.5 text-sm',
        // large: 'px-8 py-3 text-base',
    };
    return sizeMap[props.size] || sizeMap.medium;
});

const interactivityClasses = computed(() => {
    const classes = [];
    if (props.loading) classes.push('cursor-wait');
    if (props.disabled) classes.push('cursor-not-allowed', 'opacity-70');
    return classes;
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
