<template>
    <div
        class="grow-0 sm:rounded-lg overflow-hidden shadow-lg shadow-black/5 flex m-auto max-w-screen-md w-full max-sm:bg-white max-sm:absolute max-sm:inset-0"
    >
        <button
            aria-label="Menü schließen"
            class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
            @click="layout.login = false"
        >
            <Icon name="close" class="m-auto" :size="22"></Icon>
        </button>

        <NuxtImg
            width="380"
            height="500"
            src="/images/login.jpg"
            alt="Fahrzeuge"
            sizes="0 md:380px"
            class="h-full bg-stone-400 object-cover object-center w-1/2 hidden md:block"
        />
        <FormWrapper
            ref="form"
            :keypress-enter="submitForm"
            autocomplete="false"
            class="grid gap-4 md:w-1/2 w-full self-center py-20 px-12 bg-white sm:h-full"
        >
            <FrontpageHeadline level="4">Anmelden</FrontpageHeadline>
            <FormInput
                v-model="formData.email"
                required
                focus
                name="email"
                label="E-Mail"
                placeholder="E-Mail"
                type="text"
            />
            <FormInput
                v-model="formData.password"
                required
                focus
                name="password"
                label="Passwort"
                placeholder="Passwort"
                type="password"
            />
            <FormCheckbox v-model="formData.remember" label="eingeloggt bleiben" name="keeplogged" />
            <FrontpageButton :loading="loading" class="mt-4 w-full h-11" label="Anmelden" @click="submitForm" />

            <div class="text-xs text-center text-gray-500">
                Noch kein Account?
                <NuxtLink
                    to="/mitglied-werden#formular"
                    class="hover:underline hover:text-gray-800 duration-100"
                    @click="layout.login = false"
                    >Jetzt Mitglied werden</NuxtLink
                >
            </div>
        </FormWrapper>
    </div>
</template>

<script setup>
const { account, layout } = useStore();

const form = ref(null);
const loading = ref(false);
const formData = reactive({
    email: '',
    password: '',
    remember: false,
});

onUnmounted(() => {
    layout.value.login = false;
});

async function submitForm() {
    loading.value = true;

    try {
        await $fetch('/api/account/login', {
            headers: useRequestHeaders(),
            method: 'POST',
            body: formData,
        });

        const basicAccount = await $fetch('/api/account/check', {
            headers: useRequestHeaders(['cookie']),
            method: 'POST',
        });

        setTimeout(() => {
            loading.value = false;
            account.value = basicAccount;
            navigateTo('/account');
        }, 500);
    } catch (error) {
        loading.value = false;
        form.value.handleErrors(error.data.message);
    }
}
</script>
