export const useStore = () => {
    const account = useState('account', () => null);

    const layout = useState('layout', () => {
        return {
            open: false,
            login: false,
            scout: false,
            finder: false,
            newsletter: false,
        };
    });

    return { account, layout };
};
