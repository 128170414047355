<template>
    <div class="relative text-sm">
        <label v-if="label || $slots.label" class="mb-1 line-clamp-1 text-gray-700">
            <slot name="label">{{ label }}</slot>
            <span v-if="required" class="text-red-600 font-medium"> * </span>
        </label>

        <div
            class="bg-white border border-slate-300/70 rounded-md relative w-full grid items-center overflow-hidden"
            :class="[
                { '!border-sky-600': isFocus },
                { '!bg-red-50/70 !border-red-300/80': messages.length },
                { '!bg-slate-300/15 !border-slate-200/70': disabled },
                containerClass,
            ]"
        >
            <slot name="input-prepend" :input="input" :is-focus="isFocus" />

            <textarea
                ref="input"
                v-model="internalValue"
                :class="inputClass"
                :style="inputStyle"
                :disabled="disabled"
                :readonly="readonly"
                :rows="rows"
                :name="name"
                class="bg-transparent px-3 py-2 min-h-16 max-h-72 focus:outline-none"
                @focus="handleFocus"
                @blur="handleBlur"
                @input.stop="handleInput"
                @change="handleChange"
                :aria-label="label || $slots.label ? undefined : placeholder"
            />

            <div
                v-if="!internalValue && placeholder"
                class="truncate px-3 select-none text-gray-500 absolute left-0 top-2 pointer-events-none"
                :class="placeholderClass"
            >
                {{ placeholder }}
            </div>

            <slot name="input-append" :input="input" :is-focus="isFocus" />
        </div>

        <div v-if="messages.length" class="text-xs text-left mt-1.5 text-red-600">
            {{ messages[0] }}
        </div>

        <slot name="wrapper-append" :input="input" :is-focus="isFocus" />
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: [Number, String, null],
        required: true,
        default: '',
    },
    name: {
        type: String,
        default: null,
    },
    value: {
        type: [String, null],
        default: '',
    },
    label: {
        type: [String, null],
        default: null,
    },
    rows: {
        type: Number,
        default: 4,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    inputClass: {
        type: String,
        default: '',
    },
    placeholderClass: {
        type: String,
        default: '',
    },
    inputStyle: {
        type: [Object, String],
        default: null,
    },
    containerClass: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update:modelValue', 'reset', 'blur', 'focus']);
const isFocus = ref(false);
const input = ref(null);
const messages = ref([]);

const internalValue = ref(props.modelValue);

watch(
    () => props.modelValue,
    (newValue) => {
        internalValue.value = newValue;
    }
);

onMounted(() => {
    const fields = inject('fields', false);

    if (fields) {
        fields.push({
            name: props.name,
            modelValue: computed(() => props.modelValue),
            messages: messages.value,
            element: input.value,
        });
    }
});

function handleInput(event) {
    emit('update:modelValue', event.target.value);
    internalValue.value = event.target.value;
    messages.value.length = 0;
}

function handleChange(event) {
    if (props.disabled || props.readonly) {
        return;
    }
    emit('update:modelValue', event.target.value);
    internalValue.value = event.target.value;
    messages.value.length = 0;
}

function handleFocus(event) {
    isFocus.value = true;
    emit('focus', event);
}

function handleBlur(event) {
    isFocus.value = false;
    emit('blur', event);
}

defineExpose({
    messages,
});
</script>
