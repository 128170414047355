<template>
    <component
        :is="componentName"
        v-bind="componentProps"
        class="border border-gray-300 rounded-lg py-12 px-4 text-center text-sm bg-white"
        :class="{ 'hover:bg-gray-100 duration-100': to || hover }"
    >
        <span class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
            <Icon :name="iconName" :size="28" :weight="500" class="m-auto" />
        </span>
        <FrontpageHeadline level="5" class="mb-4 block" tag="span">{{ headline }}</FrontpageHeadline>
        <span class="hyphens-auto text-balance block">
            <slot name="default"></slot>
        </span>
    </component>
</template>

<script setup>
import { NuxtLink } from '#components';

const props = defineProps({
    iconName: {
        type: String,
        default: '',
    },

    headline: {
        type: String,
        default: '',
    },

    to: {
        type: String,
        default: '',
    },

    hover: {
        type: Boolean,
        default: false,
    },
});

const componentName = props.to ? NuxtLink : 'div';
const componentProps = props.to ? { to: props.to } : {};
</script>
