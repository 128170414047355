<template>
    <div class="grid gap-4 grid-cols-3">
        <template v-for="item in current" :key="item.id">
            <FrontpageVehicle class="col-span-full md:col-span-1" :item :loading="lazyLoading" :hideCondition />
        </template>
        <NuxtLink
            v-if="linkMore"
            to="/top-deals#fahrzeuge"
            class="flex items-center group hover:text-primary duration-150 mx-auto col-span-full mt-4 lg:mt-8"
        >
            <span class="mr-2"> Weitere Top Deals</span>

            <svg
                class="size-9 fill-gray-600 group-hover:text-primary group-hover:translate-x-4 duration-500"
                viewBox="0 -960 960 960"
            >
                <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
            </svg>

            <div class="h-5 w-1 -ml-0.5 bg-primary group-hover:-translate-x-6 duration-500"></div>
        </NuxtLink>

        <FrontpageLoadMoreButton
            v-if="loadMore && loadMoreButton"
            class="col-span-full mt-4 lg:mt-8"
            :disabled="loading"
            @click="loadMoreItems"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    vehicles: {
        type: Array,
        default: () => [],
    },

    loadMore: {
        type: Boolean,
        default: false,
    },

    linkMore: {
        type: Boolean,
        default: false,
    },

    hideCondition: {
        type: Boolean,
        default: false,
    },
});

const loading = ref(false);
const lazyLoading = ref('lazy');
const current = ref([...props.vehicles]);
const loadMoreButton = ref(props.loadMore);

async function loadMoreItems() {
    if (loading.value) {
        return;
    }

    loading.value = true;
    lazyLoading.value = 'eager';

    const response = await $fetch('/api/frontpage/component/top-deals', {
        method: 'POST',
        body: {
            index: current.value.length,
        },
    });

    current.value = [...current.value, ...response.items];

    if (current.value.length >= response.total) {
        loadMoreButton.value = false;
    }

    loading.value = false;
}
</script>
