<template>
    <span :class="[className, { 'opacity-0 overflow-hidden': !loaded }]" :style="style">{{ name }}</span>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
    },

    size: {
        type: Number,
        default: 24,
    },

    rounded: {
        type: Boolean,
    },

    sharp: {
        type: Boolean,
    },

    outlined: {
        type: Boolean,
    },

    fill: {
        type: Boolean,
        default: false,
    },

    weight: {
        type: Number,
        default: 300,

        validator(value) {
            return [100, 200, 300, 400, 500, 600, 700].includes(value);
        },
    },

    opticalSize: {
        type: Number,
        default: 48,

        validator(value) {
            return [20, 24, 40, 48].includes(value);
        },
    },
});

const loaded = ref(false);

import('material-symbols').then(() => {
    loaded.value = true;
});

const className = computed(() => {
    if (props.outlined) {
        return 'material-symbols-outlined';
    }

    if (props.sharp) {
        return 'material-symbols-sharp';
    }

    return 'material-symbols-rounded';
});

const style = computed(() => {
    const wght = props.weight;
    const fill = props.fill ? 1 : 0;
    const opsz = props.opticalSize;

    return {
        fontSize: `${props.size}px`,
        width: `${props.size}px`,
        height: `${props.size}px`,
        fontVariationSettings: `'FILL' ${fill}, 'wght' ${wght}, 'GRAD' 0, 'opsz' ${opsz}`,
    };
});
</script>
