<template>
    <div class="relative text-sm" v-bind="$attrs">
        <div v-if="label || $slots.label" class="mb-1 line-clamp-1 text-gray-700">
            <slot name="label">
                {{ label }}
            </slot>
            <span v-if="required" class="text-red-600 font-medium"> * </span>
        </div>

        <div
            class="bg-white border border-slate-300/70 rounded-md overflow-hidden relative w-full min-h-10 grid items-center"
            :class="[
                { '!border-sky-600': isFocused },
                { '!bg-red-50/70 !border-red-300/80': messages.length },
                { '!bg-slate-300/15 !border-slate-200/70': disabled },
                containerClass,
            ]"
        >
            <select
                ref="select"
                :name="name"
                :required="required"
                :disabled="disabled"
                :readonly="readonly"
                :value="modelValue"
                class="bg-transparent inset-0 absolute w-full h-full px-3 appearance-none opacity-0"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="handleChange"
                @click="handleClick"
                @keypress.enter.stop
                :aria-label="label || $slots.label ? undefined : placeholder"
            >
                <template v-for="option in filteredOptions" :key="option[keyName]">
                    <option :value="option[keyValue]">
                        {{ option[keyName] }}
                    </option>
                </template>
            </select>
            <div class="absolute inset-0 cursor-pointer" @click="((menu = true), select.focus())" />

            <Menu v-model="menu" :reference="select" direction="bottom" :auto-width="true" :offset="[0, 4]">
                <FormInput v-if="searchBox" v-model="searchQuery" placeholder="Suchbegriff eingeben" />
                <div v-if="menu" class="max-h-72 overflow-x-hidden overflow-y-auto">
                    <template v-for="option in filteredOptions" :key="option[keyName]">
                        <MenuItem :active="option[keyValue] == modelValue" @click="updateValue(option)">
                            {{ option[keyName] }}
                        </MenuItem>
                    </template>
                </div>
            </Menu>

            <div v-if="!modelValue && placeholder" class="truncate px-3 select-none text-gray-500">
                {{ placeholder }}
            </div>

            <div v-if="modelValue" class="truncate px-3 select-none">
                {{ modelName }}
            </div>

            <Icon
                v-if="!modelValue"
                name="keyboard_arrow_down"
                :size="20"
                class="absolute right-2.5 pointer-events-none text-gray-500/60"
            />

            <button
                v-if="resetIcon && modelValue"
                type="button"
                tabindex="-1"
                class="absolute right-2 top-1/2 -translate-y-1/2 rounded-full duration-200 hover:bg-slate-200/70 text-gray-500/60 hover:text-gray-500 p-1 aspect-square"
                @click="handleResetClick"
            >
                <Icon name="close" :size="18" />
            </button>
        </div>

        <div v-if="messages.length" class="text-xs text-left mt-1.5 text-red-600">
            {{ messages[0] }}
        </div>

        <slot name="wrapper-append" />
    </div>
</template>
<script setup>
const props = defineProps({
    name: {
        type: String,
        default: '',
    },

    label: {
        type: String,
        default: '',
    },

    placeholder: {
        type: String,
        default: '',
    },

    required: {
        type: Boolean,
        default: false,
    },

    readonly: {
        type: Boolean,
        default: false,
    },

    disabled: {
        type: Boolean,
        default: false,
    },

    options: {
        type: Array,
        default: () => [],
    },

    keyName: {
        type: String,
        default: 'text',
    },

    keyValue: {
        type: String,
        default: 'value',
    },

    disabledOptions: {
        type: Array,
        default: () => [],
    },

    containerClass: {
        type: String,
        default: '',
    },

    resetIcon: {
        type: Boolean,
        default: true,
    },

    searchBox: {
        type: Boolean,
        default: true,
    },

    modelValue: {
        required: true,
        default: '',
    },
});

const menu = ref(false);
const isFocused = ref(false);
const messages = ref([]);
const select = ref(null);
const searchQuery = ref('');

const emit = defineEmits(['update:modelValue', 'change', 'reset', 'focus', 'blur']);

onMounted(() => {
    const fields = inject('fields', false);
    if (fields) {
        fields.push({
            name: props.name,
            modelValue: props.modelValue,
            messages: messages.value,
            element: select.value,
        });
    }
});

const filteredOptions = computed(() => {
    return props.options.filter(
        (option) =>
            !props.disabledOptions.includes(option[props.keyValue]) &&
            option[props.keyName].toLowerCase().includes(searchQuery.value.toLowerCase())
    );
});

const modelName = computed(() => {
    const option = props.options.find((option) => option[props.keyValue] == props.modelValue);
    return option?.[props.keyName] || props.modelValue;
});

function updateValue(option) {
    if (option.disabled || props.disabledOptions.includes(option[props.keyValue])) return;

    menu.value = false;
    emit('update:modelValue', option[props.keyValue]);

    messages.value.length = 0;
    select.value.blur();
    emit('change', option);
}

function handleFocus(event) {
    isFocused.value = true;
    document.addEventListener('click', handleClickOutside);
    emit('focus', event);
}

function handleClick(event) {
    menu.value = true;
    event.preventDefault();
    event.stopPropagation();
}

function handleChange(event) {
    if (props.disabled || props.readonly) {
        return;
    }

    emit('update:modelValue', event.target.value);
    messages.value.length = 0;
}

function handleBlur(event) {
    nextTick(() => {
        if (!select.value.parentElement.contains(event.relatedTarget)) {
            isFocused.value = false;
            document.removeEventListener('click', handleClickOutside);
            emit('blur', event);
        }
    });
}

function handleResetClick() {
    select.value.value = '';
    emit('update:modelValue', '');
    emit('change', '');
    emit('reset', '');
}

function handleClickOutside(event) {
    if (!select.value.contains(event.target)) {
        isFocused.value = false;
        document.removeEventListener('click', handleClickOutside);
    }
}

defineExpose({
    messages,
});
</script>
