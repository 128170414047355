<template>
    <div ref="container" class="keen-slider max-w-2xl mx-auto loading group pointer-events-none select-none">
        <template v-for="item in items" :key="item.src">
            <div class="keen-slider__slide shrink-0 max-w-fit group-[.loading]:mr-10">
                <NuxtImg :width="item.width" :height="item.height" loading="lazy" :src="item.src" :alt="item.alt" />
            </div>
        </template>
    </div>
</template>

<script setup>
let observer = null;

const items = [
    { src: '/partner/abarth.jpg', alt: 'Abarth Logo', width: 80, height: 80 },
    { src: '/partner/alfa-romeo.jpg', alt: 'Alfa Romeo Logo', width: 80, height: 80 },
    { src: '/partner/citroen.jpg', alt: 'Citroen Logo', width: 80, height: 80 },
    { src: '/partner/ds-automobiles.jpg', alt: 'DS-Automobiles Logo', width: 80, height: 80 },
    { src: '/partner/fiat.jpg', alt: 'Fiat Logo', width: 80, height: 80 },
    { src: '/partner/fiat-professional.jpg', alt: 'Fiat Professional Logo', width: 80, height: 80 },
    { src: '/partner/hyundai.jpg', alt: 'Hyundai Logo', width: 80, height: 80 },
    { src: '/partner/jeep.jpg', alt: 'Jeep Logo', width: 80, height: 80 },
    { src: '/partner/kia.jpg', alt: 'Kia Logo', width: 80, height: 80 },
    { src: '/partner/lancia.jpg', alt: 'Lancia Logo', width: 120, height: 80 },
    { src: '/partner/leapmotor.jpg', alt: 'Leapmotor Logo', width: 160, height: 80 },
    { src: '/partner/opel.jpg', alt: 'Opel Logo', width: 80, height: 80 },
    { src: '/partner/nissan.jpg', alt: 'Nissan Logo', width: 80, height: 80 },
    { src: '/partner/peugeot.jpg', alt: 'Peugeot Logo', width: 80, height: 80 },
];

const animation = { duration: 10000, easing: (t) => t };
const move = ref(false);

const [container, slider] = useKeenSlider({
    loop: true,
    renderMode: 'performance',
    drag: false,

    slides: {
        perView: 'auto',
        spacing: 40,
    },

    created(s) {
        s.container.classList.remove('loading');

        // new
        // s.moveToIdx(5, true, animation);
    },
    updated(s) {
        if (move.value) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        }
    },
    animationEnded(s) {
        if (move.value) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        }
    },
});

const handleIntersection = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            move.value = true;
            slider.value.moveToIdx(5, true, animation);
        } else {
            move.value = false;
        }
    });
};

onMounted(() => {
    if (container.value) {
        observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.1,
        });
        observer.observe(container.value);
    }
});

onUnmounted(() => {
    if (observer) {
        observer.disconnect();
    }
});
</script>
