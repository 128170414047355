<template>
    <div
        class="grow-0 sm:rounded-lg shadow-lg shadow-black/5 flex flex-col px-8 sm:px-12 py-20 items-start justify-center m-auto max-w-screen-sm w-full bg-white max-sm:absolute max-sm:inset-0"
    >
        <button
            aria-label="Fenster schließen"
            class="absolute size-9 bg-gray-100/70 hover:bg-gray-100 rounded-full top-4 right-4 flex text-gray-500 duration-100 sm:hidden"
            @click="layout.newsletter = false"
        >
            <Icon name="close" class="m-auto" :size="22"></Icon>
        </button>

        <FrontpageHeadline level="3" class="mb-8">Verpassen Sie keine Angebote!</FrontpageHeadline>
        <p>
            Registrieren Sie sich kostenfrei und abonieren die <strong>OTK insight</strong> gratis. Sie können die OTK
            insight jederzeit wieder abbestellen.
        </p>

        <NuxtLink to="/mitglied-werden#formular" class="mt-12 block w-fit" @click="layout.newsletter = false">
            <FrontpageButton>Mitglied werden</FrontpageButton>
        </NuxtLink>
    </div>
</template>

<script setup>
const { layout } = useStore();
</script>
